import { computed, inject, Injectable, signal } from '@angular/core';
import { ApiEventsService } from '../shared/services/api-events.service';
import { filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { Languages, TranslationsService } from '@goatsports/shared/util';
import {
  GroupsService,
  NotificationService,
  RoleType,
  UsersService,
} from '@goatsports/core/data-access';
import { CurrentUserService } from '../shared/services/current-user.service';
import { MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { ProfileMenuItem } from '../shared/models/profile-menu-item';
import { ApplicationStateService } from '../shared/services/application-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { BreadCrumbService } from '../shared/services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { ErrorService } from '../shared/services/error.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

type State = {
  unreadMessagesCount: number;
  unreadNotificationsCount: number;
};

@Injectable()
export class ShellService {
  private readonly errorService = inject(ErrorService);
  private readonly currentUserService = inject(CurrentUserService);
  private readonly apiEventsService = inject(ApiEventsService);
  private readonly translationsService = inject(TranslationsService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly groupService = inject(GroupsService);
  private readonly notificationsService = inject(NotificationService);
  private readonly applicationStateService = inject(ApplicationStateService);
  private readonly breadCrumbService = inject(BreadCrumbService);
  private readonly usersService = inject(UsersService);
  private readonly router = inject(Router);
  private readonly titleService = inject(Title);

  private readonly INITIAL_STATE: State = {
    unreadMessagesCount: 0,
    unreadNotificationsCount: 0,
  };

  private readonly state = signal<State>(this.INITIAL_STATE);

  // selectors
  unreadMessagesCount = computed(() => this.state().unreadMessagesCount);
  unreadNotificationsCount = computed(
    () => this.state().unreadNotificationsCount,
  );

  isErrorThrown = toSignal(this.errorService.isErrorThrown$);

  // sources

  menuItems = toSignal(
    this.applicationStateService.languageChanged$.pipe(
      map((language) => {
        return this.loadMenuItems(language);
      }),
    ),
    { initialValue: [] },
  );

  private unreadMessagesCount$ = this.fetchGroupsUnreadMessagesCount().pipe(
    switchMap((initialValue) =>
      this.apiEventsService.unreadMessagesCount$.pipe(
        map((res) => res.totalUnreadCount),
        startWith(initialValue),
      ),
    ),
  );

  private unreadNotificationsCount$ = this.fetchNotificationsUnreadCount().pipe(
    switchMap((initialValue) =>
      this.apiEventsService.unreadNotificationsCount$.pipe(
        map((res) => res.unreadCount),
        startWith(initialValue),
      ),
    ),
  );

  language = toSignal(this.applicationStateService.languageChanged$, {
    initialValue: 'en',
  });

  routerUrl = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap((events) => {
        this.breadCrumbService.publishBreadCrumbItems(
          (events as any).urlAfterRedirects.slice(1).split('/'),
        );
        this.errorService.clearServerErrors();
      }),
      map(
        (events) =>
          ((events as any)?.urlAfterRedirects as string) ?? this.router.url,
      ),
    ),
    { initialValue: this.router.url },
  );

  constructor() {
    this.fetchTeamUid().pipe(takeUntilDestroyed()).subscribe();

    this.unreadMessagesCount$
      .pipe(takeUntilDestroyed())
      .subscribe((unreadMessagesCount) => {
        this.state.update((state) => ({
          ...state,
          unreadMessagesCount,
        }));
      });

    this.unreadNotificationsCount$
      .pipe(takeUntilDestroyed())
      .subscribe((unreadNotificationsCount) => {
        this.state.update((state) => ({
          ...state,
          unreadNotificationsCount,
        }));
      });
  }

  private fetchTeamUid() {
    return this.currentUserRole !== RoleType.Translator
      ? this.usersService.usersTeamGet().pipe(
          tap((res) => {
            if (res?.name) {
              this.titleService.setTitle(res.name);
            }
          }),
          map((res) => res?.teamUid),
        )
      : of(undefined);
  }

  get currentUserRole() {

    return this.currentUserService.currentUserRole 
  }

  private fetchGroupsUnreadMessagesCount() {
    return this.currentUserRole !== RoleType.Translator
      ? this.groupService.groupsUnreadCountGet()
      : of(0);
  }

  private fetchNotificationsUnreadCount() {
    return this.currentUserRole !== RoleType.Translator
      ? this.notificationsService
          .notificationUnreadCountGet()
          .pipe(map((res) => res.unreadCount ?? 0))
      : of(0);
  }

  get currentUserEmail() {
    const emailString = String(this.currentUserService.currentUserEmail); // konvertuj u string
    const firstEmail = emailString.split(',')[0].trim(); // uzmi prvi email
    return firstEmail;
  }

  changeLanguage(text: string) {
    const selectedLanguage = Languages.availableLanguages.find(
      (x) => x.label === text,
    )?.id;

    if (!selectedLanguage) return;

    this.translationsService.changeLanguage(selectedLanguage);
  }

  private loadMenuItems(languageId: string) {
    const items: MenuItemModel[] = [
      {
        text: ProfileMenuItem.Icon,
        items: [
          { text: ProfileMenuItem.Profile },
          { text: ProfileMenuItem.ChangePassword },
          {
            text: ProfileMenuItem.Language,
            items: Languages.availableLanguages.map((x) => ({
              text: x.label,
              id: x.id === languageId ? ProfileMenuItem.Language : '',
            })),
          },
          { text: ProfileMenuItem.Logout },
        ],
      },
    ];
    return items;
  }

  logout() {
    this.authenticationService.logout();
  }
}
